import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/button'
import Input from 'components/form/input'
import AddIcon from 'components/icons/AddIcon'
import Skeleton from 'components/Skeleton'
import { privateRequest } from 'config/axios.config'
import addTool from 'features/academy/addTool'
import MarketingToolsCard from 'features/academy/marketingToolsCard'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export default function MarketingToolsPage() {
  const addNewCourseModal = useModal(addTool)
  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery<Tool[], Error>(['tools', search], async () => {
    try {
      const res = await privateRequest.get(`admin/marketing-tools?search=${search}`)
      return res.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card'>
      <div className='flex gap-3 justify-end mb-6'>
        <Button
          onClick={() => addNewCourseModal.show({ title: 'Add New Tool' })}
          className='!rounded-full'
        >
          <AddIcon size={14} /> Add New
        </Button>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputClassName='!rounded-full'
          placeholder='Search Courses'
          afterFix='search'
        />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6'>
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='w-full h-40 mb-4' />
                  <Skeleton className='w-full h-4 mb-4' />
                  <div className='grid grid-cols-2 gap-9'>
                    <Skeleton className='w-full h-9' />
                    <Skeleton className='w-full h-9' />
                  </div>
                </div>
              ))
          : data?.map((tool) => <MarketingToolsCard key={tool.id} tool={tool} />)}
      </div>
    </div>
  )
}
