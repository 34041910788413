import { useState } from 'react'

import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Tabs from 'components/Tabs'
import Input from 'components/form/input'
import { privateRequest } from 'config/axios.config'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import { getStatistics } from 'queries/dashboard'
import { useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

interface WalletFilter {
  search: string
}

const tabs: TabOption[] = [
  {
    label: 'Approved',
    value: 'approved',
  },
  // {
  //   label: 'Declined',
  //   value: 'declined',
  // },
]

export default function WithdrawalsHistoryPage() {
  const [filters, setFilters] = useState<WalletFilter>({
    search: '',
  })

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const { data: statistics } = getStatistics()

  const { data, isLoading } = useQuery<WithdrawalHistory[], Error>(
    ['withdrawal-history', selectedTab.value, filters.search],
    async () => {
      try {
        const res = await privateRequest.get(
          `admin/withdrawal/${selectedTab.value}?search=${filters.search}`,
        )
        return res.data.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <AnalyticCard
        className='max-w-xs'
        title='Total Withdrawals Amount'
        value={(statistics?.total_confirmed_withdrawal ?? '0.00') + ' USDT'}
      />
      <Devider />
      <div className='card'>
        <div className='flex flex-wrap justify-between mb-6'>
          <Tabs options={tabs} selected={selectedTab} selectHandler={setSelectedTab} />
          <div className='flex gap-5 flex-wrap justify-end items-center'>
            {/* From <InputDatePicker value={date} onChange={onChange} /> To{' '}
            <InputDatePicker value={date} onChange={onChange} /> */}
            <Input
              size='lg'
              name='search'
              value={filters.search}
              onChange={changeHandler}
              afterFix='search'
              placeholder='Search TxID'
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td>TXID</td>
              <td>Timestamp</td>
              <td>Amount</td>
              <td>Note</td>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => (
              <tr key={row.id}>
                <td>{row.reference}</td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? <Loader className='py-28' /> : !data?.length ? <NoData /> : null}
      </div>
    </>
  )
}
