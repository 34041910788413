import copy from 'copy-to-clipboard'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { FiCopy } from 'react-icons/fi'

import Badge from 'components/Badge'
import InputDatePicker from 'components/DatePicker'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Status from 'components/Status'
import Title from 'components/Title'
import Button from 'components/form/button'
import Input from 'components/form/input'
import Select from 'components/form/select'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import { getTransactions } from 'queries/walletQuires'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'

type SortByOptions = { label: string; value: TransactionSourceType }

interface WalletFilter {
  search: string
  sortby: SortByOptions
}

const sortbyOptions: SortByOptions[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Dual Team',
    value: 'dual_team',
  },
  {
    label: 'Signup',
    value: 'signup',
  },
  {
    label: 'Direct Referral',
    value: 'direct_referral',
  },
]

export default function WalletPage() {
  const [date, onChange] = useState<Date | undefined>()
  const [filters, setFilters] = useState<WalletFilter>({
    search: '',
    sortby: sortbyOptions[0],
  })

  // const { data: balances, isLoading: isLoadingBalances } = getBalances()
  const { data: transactions, isLoading } = getTransactions(
    filters.sortby.value,
    'c-reward',
    filters.search,
  )

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
        {/* {isLoading ? (
        <>
          {Array(3)
            .fill(0)
            .map((_, key) => (
              <div className='card' key={key}>
                <Skeleton className='h-6 mb-8 w-60' />
                <Skeleton className='h-6 w-32' />
              </div>
            ))}
        </>
      ) : (
        <> */}
        <AnalyticCard title='R-WALLET Balance' value={'10000.00 USDT'}>
          <div className='flex mt-8 gap-3'>
            <Button size='lg'>Transfer</Button>
            <Button variant='outlined' size='lg'>
              Deposit
            </Button>
          </div>
        </AnalyticCard>
        <AnalyticCard title='C-REWARDS Balance' value={'46515612.00 USDT'}>
          <div className='flex mt-8 gap-3'>
            <Button size='lg'>Transfer</Button>
            <Button variant='outlined' size='lg'>
              Deposit
            </Button>
          </div>
        </AnalyticCard>
        {/* </>
      )} */}
      </div>
      <Devider />
      <Title className='mb-4'>Latest Transactions</Title>
      <div className='card'>
        <div className='flex gap-5 flex-wrap mb-6 justify-end items-center'>
          From <InputDatePicker value={date} onChange={onChange} /> To{' '}
          <InputDatePicker value={date} onChange={onChange} />
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix='search'
            placeholder='Search TxID'
          />
          <Select
            className='!min-w-[200px]'
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={sortbyOptions}
          />
        </div>
        <div className='max-h-[calc(100vh-270px)] overflow-auto pr-3'>
          <table>
            <thead className='sticky left-0 top-0 bg-white'>
              <tr>
                <td>TXID</td>
                <td>Timestamp</td>
                <td>Amount</td>
                <td>Note</td>
                <td align='right'>Status</td>
              </tr>
            </thead>
            <tbody>
              {transactions?.map((row) => (
                <tr key={row.id}>
                  <td>
                    {row.reference}
                    <Badge
                      onClick={() => {
                        copy(row.reference)
                        toast.success('Copied')
                      }}
                    >
                      <FiCopy />
                    </Badge>
                  </td>
                  <td>{dateFormatter(row.created_at)}</td>
                  <td>
                    {row.type === 'credit' ? (
                      <span className='text-green-500'>+</span>
                    ) : (
                      <span className='text-red-500'>-</span>
                    )}{' '}
                    {row.amount}
                  </td>
                  <td>{row.note}</td>
                  <td align='right'>
                    <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isLoading ? <Loader className='py-28' /> : !transactions?.length ? <NoData /> : null}
      </div>
    </div>
  )
}
