import { BsYoutube } from 'react-icons/bs'

import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/button'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'
import editTool from './editTool'

export default function MarketingToolsCard({ tool }: { tool: Tool }) {
  const editToolModal = useModal(editTool)
  const confirmation = useModal(confirmationModal)
  const queryClient = useQueryClient()
  const deleteCourse = useMutation(
    async (id: number) => {
      try {
        const res = await privateRequest.delete(`admin/marketing-tools/${id}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tools')
      },
    },
  )

  return (
    <div className='border border-primary-shade p-3'>
      <div className='relative mb-3'>
        {tool.thumbnail_path && (
          <img className='h-full w-full object-cover' src={appUrl + tool.thumbnail_path} alt='' />
        )}
        {/* <div className='absolute right-0 bottom-2 bg-[#0B2628]/50 rounded-l-full text-white text-xs font-medium px-3'>
          10:45:12
        </div> */}
        {tool.type === 'video' && (
          <BsYoutube
            className='text-white absolute left-1/2
         top-1/2 -translate-x-1/2 -translate-y-1/2'
            size={50}
          />
        )}
      </div>
      <h3 className='text-lg font-medium text-dark mb-1'>{tool.title}</h3>
      {/* <div className='text-sm flex justify-between items-center'>
        <p>Lock for Free</p>
        <Switch size='md' />
      </div> */}
      <div className='grid grid-cols-2 gap-2 mt-4'>
        <Button onClick={() => editToolModal.show({ title: 'Update New Tool', tool })} size='md'>
          Edit
        </Button>
        <Button
          onClick={() =>
            confirmation
              .show({
                title: 'Are you sure you want to delete this?',
                buttonText: 'Delete',
                phase: 'danger',
              })
              .then(() => {
                toast.promise(deleteCourse.mutateAsync(tool.id), {
                  loading: 'Deleting...',
                  success: (res) => res.message ?? 'Deleted successfully',
                  error: (err) => err.message ?? 'Something went wrong',
                })
              })
          }
          size='md'
          variant='outlined'
        >
          Delete
        </Button>
      </div>
    </div>
  )
}
