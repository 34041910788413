import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/button'
import Input from 'components/form/input'
import AddIcon from 'components/icons/AddIcon'
import Skeleton from 'components/Skeleton'
import { privateRequest } from 'config/axios.config'
import addCourseCategory from 'features/academy/addCourseCategory'
import updateCourseCategory from 'features/academy/updateCourseCategory'
import folderImg from 'images/folder.png'
import { useState } from 'react'
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

export default function CourseCategoriesPage() {
  const addNewCategoryModal = useModal(addCourseCategory)
  const updateCourseCategoryModal = useModal(updateCourseCategory)

  const [search, setSearch] = useState<string>('')

  const { data, isLoading } = useQuery<CourseCategory[], Error>(
    ['course-categories', search],
    async () => {
      try {
        const res = await privateRequest.get(
          `admin/courses/categories${search ? `?category_name=${search}` : ''}`,
        )
        return res.data.categories
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  return (
    <div className='card'>
      <div className='flex gap-3 justify-end mb-6'>
        <Button
          onClick={() => addNewCategoryModal.show({ title: 'Create Course Category' })}
          className='!rounded-full'
        >
          <AddIcon size={14} /> Add Category
        </Button>
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputClassName='!rounded-full'
          placeholder='Search Folders'
          afterFix='search'
        />
      </div>
      <div className='grid grid-cols-3 sm:grid-cols-5 md:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 gap-6 xl:gap-8'>
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='w-32 h-24 mb-4' />
                  <Skeleton className='w-32 h-4' />
                </div>
              ))
          : data?.map((category) => (
              <div key={category.id} className='relative group'>
                <Link to={`/courses/${category.id}`}>
                  <img className='mx-auto' src={folderImg} alt='' />
                  <h2 className='text-center mt-3'>{category.name}</h2>
                </Link>
                <MdOutlineDriveFileRenameOutline
                  className='absolute right-0 top-0 h-6 w-6 p-1 rounded-md bg-red-400 text-white opacity-0 group-hover:opacity-100 hover:scale-110 transition-all cursor-pointer'
                  onClick={() =>
                    updateCourseCategoryModal.show({ title: 'Update Category', data: category })
                  }
                />
              </div>
            ))}
      </div>
    </div>
  )
}
