import { useModal } from '@ebay/nice-modal-react'
import Button from 'components/form/button'
import AddIcon from 'components/icons/AddIcon'
import Skeleton from 'components/Skeleton'
import addEbookCategory from 'features/academy/addEbookCategory'
import updateEbookCategory from 'features/academy/updateEbookCategory'
import folderImg from 'images/folder.png'
import { useEbookCategories } from 'queries/academy'
import { MdOutlineDriveFileRenameOutline } from 'react-icons/md'
import { Link } from 'react-router-dom'

export default function EbookCategoriesPage() {
  const addEbookCategoryModal = useModal(addEbookCategory)
  const updateEbookCategoryModal = useModal(updateEbookCategory)

  const { data, isLoading } = useEbookCategories()

  return (
    <div className='card'>
      <div className='flex gap-3 justify-end mb-6'>
        <Button
          onClick={() => addEbookCategoryModal.show({ title: 'Create Ebook Category' })}
          className='!rounded-full'
        >
          <AddIcon size={14} /> Add Category
        </Button>
      </div>
      <div className='grid grid-cols-3 sm:grid-cols-5 md:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 gap-6 xl:gap-8'>
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, key) => (
                <div key={key}>
                  <Skeleton className='w-32 h-24 mb-4' />
                  <Skeleton className='w-32 h-4' />
                </div>
              ))
          : data?.map((category) => (
              <div key={category.id} className='relative group'>
                <Link to={`/ebooks/${category.id}`}>
                  <img className='mx-auto' src={folderImg} alt='' />
                  <h2 className='text-center mt-3'>{category.category_name}</h2>
                </Link>
                <MdOutlineDriveFileRenameOutline
                  className='absolute right-0 top-0 h-6 w-6 p-1 rounded-md bg-red-400 text-white opacity-0 group-hover:opacity-100 hover:scale-110 transition-all cursor-pointer'
                  onClick={() =>
                    updateEbookCategoryModal.show({ title: 'Update Category', data: category })
                  }
                />
              </div>
            ))}
      </div>
    </div>
  )
}
