import { useModal } from '@ebay/nice-modal-react'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/form/button'
import Input from 'components/form/input'
import AddIcon from 'components/icons/AddIcon'
import NoData from 'components/NoData'
import Skeleton from 'components/Skeleton'
import { privateRequest } from 'config/axios.config'
import addNewCourse from 'features/academy/addNewCourse'
import CourseCard from 'features/academy/courseCard'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

export default function CoursePage() {
  const addNewCourseModal = useModal(addNewCourse)

  const [search, setSearch] = useState<string>('')

  const { id } = useParams()

  const { data, isLoading } = useQuery<Course[], Error>(['courses', search, id], async () => {
    try {
      const res = await privateRequest.get(`admin/course?course_name=${search}&category_id=${id}`)
      return res.data.courses
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card'>
      <div className='flex gap-3 mb-6 items-center'>
        <BreadCrumb prevPage='/academy/course/categories' title='All Categories' />
        <Button
          onClick={() =>
            addNewCourseModal.show({ title: 'Create New Course', category_id: id as string })
          }
          className='!rounded-full ml-auto'
        >
          <AddIcon size={14} /> Add New
        </Button>
        <Input
          inputClassName='!rounded-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search Course'
          afterFix='search'
        />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6'>
        {isLoading
          ? Array(4)
              .fill(0)
              .map((_, key) => (
                <div key={key} className='border border-primary-shade p-3'>
                  <Skeleton className='h-40 w-full mb-3' />
                  <Skeleton className='h-5 w-full mb-4' />
                  <Skeleton className='h-3 w-full mb-2' />
                  <Skeleton className='h-3 w-full mb-7' />
                  <Skeleton className='h-3 w-full mb-5' />
                  <div className='grid grid-cols-2 gap-4'>
                    <Skeleton className='h-10 w-full' />
                    <Skeleton className='h-10 w-full' />
                  </div>
                </div>
              ))
          : data?.map((course: any) => <CourseCard key={course.id} course={course} />)}
      </div>
      {!isLoading && !data?.length && <NoData />}
    </div>
  )
}
