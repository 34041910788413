import NiceModal from '@ebay/nice-modal-react'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Toaster } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import queryClient from './config/queyClient.config'
import reportWebVitals from './reportWebVitals'

import AuthContextProvider from 'context/AuthContext'
import { ReactQueryDevtools } from 'react-query/devtools'
import './global.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <NiceModal.Provider>
          <BrowserRouter>
            <App />
            <Toaster />
            <ReactQueryDevtools initialIsOpen={false} />
          </BrowserRouter>
        </NiceModal.Provider>
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
