import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { AiFillDelete, AiOutlineCheck } from 'react-icons/ai'

import Button from 'components/form/button'
import Modal from '.'

type Props = {
  title?: string
  phase?: 'danger' | 'success'
  buttonText?: string
}

export default NiceModal.create(({ title, phase, buttonText = 'Yes' }: Props) => {
  // Use a hook to manage the modal state
  const modal = useModal()

  return (
    <Modal visible={modal.visible} onCancel={() => modal.hide()} className='max-w-lg'>
      {phase && (
        <div className='h-20 w-20 bg-primary-shade grid place-items-center'>
          {phase === 'danger' && <AiFillDelete size={35} color='red' />}
          {phase === 'success' && <AiOutlineCheck size={35} color='green' />}
        </div>
      )}
      <h2 className='text-2xl leading-10 font-semibold mt-5'>{title}</h2>
      <div className='grid grid-cols-2 gap-4 mt-12'>
        <Button
          onClick={() => {
            modal.reject()
            modal.hide()
          }}
          size='md'
          color='default'
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            modal.resolve()
            modal.hide()
          }}
          size='md'
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  )
})
