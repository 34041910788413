import { useState } from 'react'

import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Badge from 'components/Badge'
import Devider from 'components/Devider'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Button from 'components/form/button'
import Input from 'components/form/input'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import copy from 'copy-to-clipboard'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import { toast } from 'react-hot-toast'
import { FiCopy } from 'react-icons/fi'
import { useMutation, useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

const sortbyOptions: SortByOptions[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Dual Team',
    value: 'dual_team',
  },
  {
    label: 'Signup',
    value: 'signup',
  },
  {
    label: 'Direct Referral',
    value: 'direct_referral',
  },
]

type SortByOptions = { label: string; value: TransactionSourceType }

interface WalletFilter {
  search: string
  sortby: SortByOptions
}

export default function PendingWithdrawalsPage() {
  // const [date, onChange] = useState<Date | undefined>()
  const [filters, setFilters] = useState<WalletFilter>({
    search: '',
    sortby: sortbyOptions[0],
  })

  const confirmation = useModal(confirmationModal)

  const { data, isLoading } = useQuery<PendingWithdrawalResponse, Error>(
    ['pending-withdrawal', filters.search],
    async () => {
      try {
        const res = await privateRequest.get(`admin/withdrawal/pending?search=${filters.search}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const approveWithdrawalMutation = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/withdrawal/approve', payload)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  const approveWithdrawal = (id: number) => {
    const payload = new FormData()
    payload.append('id', id.toString())
    toast.promise(approveWithdrawalMutation.mutateAsync(payload), {
      loading: 'Approving...',
      success: (data) => data.message ?? 'Approved',
      error: (err) => err.message ?? 'Error occured',
    })
  }

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <>
      <div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-5'>
        <AnalyticCard title='Balance' value={(data?.balance ?? '0.00') + ' USDT (BEP-20)'}>
          <div className='flex items-center mt-4'>
            <p className='flex-1 select-none text-gray whitespace-nowrap text-ellipsis overflow-hidden'>
              {data?.wallet_address}
            </p>
            {data?.wallet_address && (
              <Badge
                onClick={() => {
                  copy(data?.wallet_address)
                  toast.success('Wallet address copied')
                }}
              >
                <FiCopy />
              </Badge>
            )}
          </div>
        </AnalyticCard>
        <AnalyticCard
          title='Pending Withdrawals'
          value={(data?.total_pending_withdrawal ?? '0.00') + ' USDT'}
        />
      </div>
      <Devider />
      <div className='card'>
        <div className='flex gap-5 flex-wrap mb-6 justify-end items-center'>
          {/* From <InputDatePicker value={date} onChange={onChange} /> To{' '}
          <InputDatePicker value={date} onChange={onChange} /> */}
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix='search'
            placeholder='Search TxID'
          />
          {/* <Select
            className='!min-w-[200px]'
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={sortbyOptions}
          /> */}
        </div>
        <table>
          <thead>
            <tr>
              <td>TXID</td>
              <td>Timestamp</td>
              <td>Amount</td>
              <td>Note</td>
              <td align='right'>Status</td>
            </tr>
          </thead>
          <tbody>
            {data?.transactions.map((row) => (
              <tr key={row.id}>
                <td>
                  {row.reference}{' '}
                  <Badge
                    onClick={() => {
                      copy(row.reference)
                      toast.success('Copied')
                    }}
                  >
                    <FiCopy />
                  </Badge>
                </td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  <span
                    className={clsx({
                      'text-green-500': row.type === 'credit',
                      'text-red-500': row.type === 'debit',
                    })}
                  >
                    {row.type === 'credit' ? '+' : '-'}
                    {row.amount}
                  </span>{' '}
                  USDT
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  {row.status === 'pending' && (
                    <div className='flex gap-2 justify-end'>
                      <Button
                        onClick={() =>
                          confirmation
                            .show({
                              title: 'Approve Withdrawal',
                              phase: 'success',
                            })
                            .then(() => approveWithdrawal(row.id))
                        }
                        size='md'
                      >
                        Approve
                      </Button>
                      {/* <Button size='md' color='danger' variant='outlined'>
                        Decline
                      </Button> */}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? <Loader className='py-28' /> : !data?.transactions.length ? <NoData /> : null}
      </div>
    </>
  )
}
