import ComingSoongSection from 'components/ComingSoongSection'
import RequireAuth from 'components/auth/RequireAuth'
import { AuthContext } from 'context/AuthContext'
import PageWrapper from 'layout/PageWrapper'
import { useContext, useEffect, useState } from 'react'
import { Navigate, RouteObject, useRoutes } from 'react-router-dom'
import CourseCategoriesPage from './course-categories'
import CoursesPage from './courses'
import Dashboard from './dashboard'
import EbookCategoriesPage from './ebook-categories'
import BooksPage from './ebooks'
import ExchangePage from './exchange'
import GamingRewardPage from './gaming-reward'
import LoginPage from './login'
import MarketingToolsPage from './marketing-tools'
import MembersPage from './members'
import PendingWithdrawalsPage from './pending-withdrawals'
import ProfitPage from './profit'
import StakingPage from './staking'
import SubscriptionsPage from './subscriptions'
import WalletPage from './wallet'
import WithdrawalsHistoryPage from './withdrawal-history'

const privateRoute: RouteObject[] = [
  {
    path: '/',
    element: <RequireAuth />,
    children: [
      { index: true, element: <Navigate to='dashboard' /> },
      {
        path: 'dashboard',
        element: (
          <PageWrapper title='Dashboard'>
            <Dashboard />
          </PageWrapper>
        ),
      },
      {
        path: 'wallet',
        element: (
          <PageWrapper title='R-Wallet'>
            <WalletPage />
          </PageWrapper>
        ),
      },
      {
        path: 'members/free',
        element: (
          <PageWrapper title='Free Members'>
            <MembersPage type='Free' />
          </PageWrapper>
        ),
      },
      {
        path: 'members/associate',
        element: (
          <PageWrapper title='Associate Members'>
            <MembersPage type='Associate' />
          </PageWrapper>
        ),
      },
      {
        path: 'play',
        element: (
          <PageWrapper title='Play'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare',
        element: (
          <PageWrapper title='Fincare'>
            <ComingSoongSection />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare/exchange',
        element: (
          <PageWrapper title='Exchange'>
            <ExchangePage />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare/staking',
        element: (
          <PageWrapper title='Staking'>
            <StakingPage />
          </PageWrapper>
        ),
      },
      {
        path: 'fincare/subscriptions',
        element: (
          <PageWrapper title='Subscriptions'>
            <SubscriptionsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'withdrawals/pending',
        element: (
          <PageWrapper title='Pending Withdrawals'>
            <PendingWithdrawalsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'withdrawals/history',
        element: (
          <PageWrapper title='Withdrawals History'>
            <WithdrawalsHistoryPage />
          </PageWrapper>
        ),
      },
      {
        path: 'marketing-tools',
        element: (
          <PageWrapper title='Marketing Tools'>
            <MarketingToolsPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/course/categories',
        element: (
          <PageWrapper title='Course Categories'>
            <CourseCategoriesPage />
          </PageWrapper>
        ),
      },
      {
        path: 'courses/:id',
        element: (
          <PageWrapper title='All Courses'>
            <CoursesPage />
          </PageWrapper>
        ),
      },
      {
        path: 'academy/ebook/categories',
        element: (
          <PageWrapper title='Ebook Categories'>
            <EbookCategoriesPage />
          </PageWrapper>
        ),
      },
      {
        path: 'ebooks/:id',
        element: (
          <PageWrapper title='All Books'>
            <BooksPage />
          </PageWrapper>
        ),
      },
      {
        path: 'profit-loss',
        element: (
          <PageWrapper title='Profit & Loss'>
            <ProfitPage />
          </PageWrapper>
        ),
      },
      {
        path: 'gaming-rewards',
        element: (
          <PageWrapper title='Gaming Rewards'>
            <GamingRewardPage />
          </PageWrapper>
        ),
      },
      { path: 'login', element: <Navigate to='/' /> },
      { path: '*', element: <Navigate to='/login' /> },
    ],
  },
]

const publicRoute: RouteObject[] = [
  { path: 'login', element: <LoginPage /> },
  { path: '*', element: <Navigate to='/login' /> },
]

const Routes = () => {
  const { token } = useContext(AuthContext)

  const [routes, setRoutes] = useState<RouteObject[]>(token ? privateRoute : publicRoute)

  // change routes on token state
  useEffect(() => {
    setRoutes(token ? privateRoute : publicRoute)
  }, [token])

  const appRoutes = useRoutes(routes)

  if (token) {
    return <div>{appRoutes}</div>
  }

  return <div>{appRoutes}</div>
}

export default Routes
