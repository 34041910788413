import Devider from 'components/Devider'
import Analytics from 'features/dashboard/Analytics'
import PlatformActivity from 'features/dashboard/PlatformActivity'

export default function Dashboard() {
  return (
    <div>
      <Analytics />
      <Devider />
      <PlatformActivity />
    </div>
  )
}
