import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import FileUpload from 'components/form/FileUpload'
import Input from 'components/form/input'
import Select from 'components/form/select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useEbookCategories, useTags } from 'queries/academy'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { RxUpload } from 'react-icons/rx'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'

type Props = {
  title?: string
  book: Book
}

type Form = {
  book_title: string
  book_author: string
  book_short_description: string
  book_file_url?: File
  tag_id: string
  book_category_id: string
  book_cover_image?: File
}

export default NiceModal.create(({ title, book }: Props) => {
  const modal = useModal()

  const queryClient = useQueryClient()

  const updateEbookMutation = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post(`admin/ebook/${book.id}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ebooks')
        modal.remove()
      },
    },
  )

  const { data: categories } = useEbookCategories()

  const { data: tags } = useTags()

  const [form, setForm] = useState<Form>({
    book_title: book.book_title,
    book_author: book.book_author,
    book_short_description: book.book_short_description,
    book_category_id: book.book_category_id,
    tag_id: book.tag_id,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key as keyof Form]) {
        formData.append(key, form[key as keyof Form] as any)
      }
    })

    toast.promise(updateEbookMutation.mutateAsync(formData), {
      loading: 'Updating book...',
      success: (res) => res.message ?? 'Ebook updated successfully',
      error: (err) => err.message ?? 'Failed to update ebook',
    })
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setForm({ ...form, [e.target.name]: file })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSelectChange = (e: _SelectChangeHandlerEvent) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value.value })
  }

  const categoriedOptions = categories?.map((c) => ({
    label: c.category_name,
    value: String(c.id),
  }))

  const book_file_url = form.book_file_url?.name || book.book_file_url
  const tagOptions = tags?.map((el) => ({ label: el.name as string, value: String(el.id) }))

  return (
    <Modal
      title={title}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <FileUpload
          defaultImg={appUrl + book.book_cover_image}
          label='Book Thumbnail (Optional)'
          onChange={handleFileUpload}
          name='book_cover_image'
        />
        <Input
          name='book_title'
          value={form.book_title}
          onChange={handleChange}
          label='Book Title'
          placeholder='Enter the title'
        />
        <Input
          name='book_short_description'
          value={form.book_short_description}
          onChange={handleChange}
          label='Book Description'
          placeholder='Enter the description'
        />
        <Input
          name='book_author'
          value={form.book_author}
          onChange={handleChange}
          label='Author Name'
          placeholder='@rme_username'
        />
        <Select
          label='Category'
          options={categoriedOptions ?? []}
          name='book_category_id'
          value={categoriedOptions?.find((c) => c.value === form.book_category_id)}
          onChange={handleSelectChange}
        />

        <Select
          placeholder='Select Tag'
          label='Select Tag'
          options={tagOptions ?? []}
          name='tag_id'
          value={tagOptions?.find((c) => c.value === form.tag_id)}
          onChange={handleSelectChange}
        />

        <label className='py-6 cursor-pointer w-full btn btn-secondary'>
          <div className='text-center'>
            <p>{book_file_url && book_file_url?.replace('storage/books/documents/', '')}</p>
            <div className='mt-3 inline-flex items-center gap-2'>
              <RxUpload size={20} /> Upload Video
            </div>
          </div>
          <input
            accept='application/pdf'
            name='book_file_url'
            onChange={handleFileUpload}
            type='file'
            hidden
          />
        </label>

        <Button disabled={updateEbookMutation.isLoading} fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  )
})
