import { useModal } from '@ebay/nice-modal-react'
import BreadCrumb from 'components/BreadCrumb'
import Button from 'components/form/button'
import Input from 'components/form/input'
import AddIcon from 'components/icons/AddIcon'
import NoData from 'components/NoData'
import Skeleton from 'components/Skeleton'
import { privateRequest } from 'config/axios.config'
import addNewBook from 'features/academy/addNewBook'
import EbookCard from 'features/academy/ebookCard'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { errorHandler } from 'utils/errorHandler'

export default function BooksPage() {
  const addNewBookModal = useModal(addNewBook)

  const [search, setSearch] = useState<string>('')

  const { id } = useParams()

  const { data, isLoading } = useQuery<Book[], Error>(['ebooks', search, id], async () => {
    try {
      const res = await privateRequest.get(`admin/ebook?search=${search}&category_id=${id}`)
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card'>
      <div className='flex gap-3 mb-6 items-center'>
        <BreadCrumb prevPage='/academy/ebook/categories' title='All Categories' />
        <Button
          onClick={() =>
            addNewBookModal.show({ title: 'Create New Ebook', book_category_id: id as string })
          }
          className='!rounded-full ml-auto'
        >
          <AddIcon size={14} /> Add Ebook
        </Button>
        <Input
          inputClassName='!rounded-full'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder='Search Ebook'
          afterFix='search'
        />
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-6'>
        {isLoading
          ? Array(10)
              .fill(0)
              .map((_, key) => (
                <div key={key} className='grid grid-cols-5 p-3 border border-primary-shade gap-6'>
                  <div className='col-span-2'>
                    <Skeleton className='h-40 w-full' />
                  </div>
                  <div className='col-span-3 flex flex-col gap-4'>
                    <Skeleton className='h-4 w-full' />
                    <Skeleton className='h-2 w-full' />
                    <Skeleton className='h-5 w-full mt-2' />
                    <div className='flex gap-3 mt-4'>
                      <Skeleton className='h-10 w-1/2' />
                      <Skeleton className='h-10 w-1/2' />
                    </div>
                  </div>
                </div>
              ))
          : data?.map((book: any) => <EbookCard key={book.id} book={book} />)}
      </div>
      {!isLoading && !data?.length ? <NoData /> : null}
    </div>
  )
}
