import Alert from 'components/Alert'
import Skeleton from 'components/Skeleton'
import { getStatistics } from 'queries/dashboard'
import AnalyticCard from './AnalyticCard'

export default function Analytics() {
  const { isLoading, isError, data } = getStatistics()
  if (isError) {
    return <Alert>Error</Alert>
  }

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
      {isLoading ? (
        <>
          {Array(5)
            .fill(0)
            .map((_, key) => (
              <div className='card' key={key}>
                <Skeleton className='h-6 mb-8 w-60' />
                <Skeleton className='h-6 w-32' />
              </div>
            ))}
        </>
      ) : (
        <>
          <AnalyticCard title='Total Users' value={data?.total_member_count ?? 0}>
            <h2 className='mt-2 text-slate-600'>
              Associated Member:{' '}
              <strong className='text-xl font-medium'>{data?.associate_members_count}</strong>
            </h2>
            <h2 className='mt-1 text-slate-600'>
              Free Member:{' '}
              <strong className='text-xl font-medium'>{data?.free_members_count}</strong>
            </h2>
          </AnalyticCard>
          <AnalyticCard
            title='Total Withdrawal Fees'
            value={(data?.total_withdrawal_fees ?? '0.00') + ' USDT'}
          />
          <AnalyticCard
            title='Pending Withdrawals'
            value={(data?.total_pending_withdrawal ?? '0.00') + ' USDT'}
          />
          <AnalyticCard
            title='Confirmed Withdrawals'
            value={(data?.total_confirmed_withdrawal ?? '0.00') + ' USDT'}
          />
          <AnalyticCard
            title='Total Rewards Sent'
            value={(data?.total_rewards_paid ?? '0.00') + ' USDT'}
          />
        </>
      )}
    </div>
  )
}
