import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  to?: string
  size?: ButtonSize
  type?: ButtonType
  color?: ButtonColor
  className?: string
  disabled?: boolean
  fullWidth?: boolean
  variant?: 'outlined' | 'contained'
  onClick?: () => void
}

export default function Button({
  to,
  size = 'lg',
  type = 'submit',
  color = 'primary',
  variant = 'contained',
  fullWidth = false,
  disabled = false,
  className = '',
  children,
  onClick,
}: PropsWithChildren<Props>) {
  const classNames = clsx(
    `btn btn-${color} btn-${size} btn-${variant}`,
    {
      'btn-disabled': disabled,
      'w-full': fullWidth,
    },
    className,
  )

  if (to) {
    return (
      <Link to={to} className={classNames}>
        {children}
      </Link>
    )
  }

  return (
    <button
      onClick={disabled ? (e) => e.preventDefault() : onClick}
      className={classNames}
      type={type}
    >
      {children}
    </button>
  )
}
