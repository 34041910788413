import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Status from 'components/Status'
import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'
import { StatusColorFinder } from 'utils/StatusColorFinder'

export default function PlatformActivity() {
  const { data: transactions, isLoading } = useQuery<RecentActivity[], Error>(
    'recent-activities',
    async () => {
      try {
        const res = await privateRequest.get('admin/recent-activities')
        return res.data.transactions
      } catch (error) {
        errorHandler(error)
      }
    },
  )

  return (
    <div className='card !pr-0'>
      <div className='h-[400px] pr-5 overflow-auto relative'>
        <table>
          <thead className='sticky top-0 left-0 z-10 bg-white'>
            <tr>
              <td>Timestamp</td>
              <td>TXID</td>
              <td>Amount</td>
              <td>Note</td>
              <td align='right'>Status</td>
            </tr>
          </thead>
          <tbody>
            {transactions?.map((row) => (
              <tr key={row.id}>
                <td>{dateFormatter(row.created_at)}</td>
                <td>{row.reference}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? <Loader className='mt-40' /> : !transactions?.length ? <NoData /> : null}
      </div>
    </div>
  )
}
