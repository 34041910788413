export default function AddIcon({ size = 16 }: { size?: number }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6666 16.0002C9.27731 16.0002 7.33331 14.0562 7.33331 11.6668C7.33331 9.2775 9.27731 7.3335 11.6666 7.3335C14.056 7.3335 16 9.2775 16 11.6668C16 14.0562 14.056 16.0002 11.6666 16.0002ZM11.6666 8.3335C9.82865 8.3335 8.33331 9.82883 8.33331 11.6668C8.33331 13.5048 9.82865 15.0002 11.6666 15.0002C13.5046 15.0002 15 13.5048 15 11.6668C15 9.82883 13.5046 8.3335 11.6666 8.3335Z'
        fill='currentColor'
      />
      <path
        d='M11.6667 14.0002C11.5341 14.0002 11.4069 13.9475 11.3131 13.8537C11.2194 13.7599 11.1667 13.6328 11.1667 13.5002V9.8335C11.1667 9.70089 11.2194 9.57371 11.3131 9.47994C11.4069 9.38617 11.5341 9.3335 11.6667 9.3335C11.7993 9.3335 11.9265 9.38617 12.0202 9.47994C12.114 9.57371 12.1667 9.70089 12.1667 9.8335V13.5002C12.1667 13.6328 12.114 13.7599 12.0202 13.8537C11.9265 13.9475 11.7993 14.0002 11.6667 14.0002Z'
        fill='currentColor'
      />
      <path
        d='M13.5 12.1667H9.83333C9.70073 12.1667 9.57355 12.114 9.47978 12.0202C9.38601 11.9265 9.33333 11.7993 9.33333 11.6667C9.33333 11.5341 9.38601 11.4069 9.47978 11.3131C9.57355 11.2193 9.70073 11.1667 9.83333 11.1667H13.5C13.6326 11.1667 13.7598 11.2193 13.8536 11.3131C13.9473 11.4069 14 11.5341 14 11.6667C14 11.7993 13.9473 11.9265 13.8536 12.0202C13.7598 12.114 13.6326 12.1667 13.5 12.1667ZM6.12667 14H1.83333C1.34721 13.9996 0.881101 13.8064 0.537361 13.4626C0.193621 13.1189 0.000353158 12.6528 0 12.1667L0 1.83333C0.000353158 1.34721 0.193621 0.881101 0.537361 0.537361C0.881101 0.193621 1.34721 0.000353158 1.83333 0L9.5 0C9.98612 0.000353158 10.4522 0.193621 10.796 0.537361C11.1397 0.881101 11.333 1.34721 11.3333 1.83333V5.89333C11.3333 6.02594 11.2807 6.15312 11.1869 6.24689C11.0931 6.34065 10.9659 6.39333 10.8333 6.39333C10.7007 6.39333 10.5735 6.34065 10.4798 6.24689C10.386 6.15312 10.3333 6.02594 10.3333 5.89333V1.83333C10.3333 1.374 9.95933 1 9.5 1H1.83333C1.374 1 1 1.374 1 1.83333V12.1667C1 12.626 1.374 13 1.83333 13H6.12667C6.25927 13 6.38645 13.0527 6.48022 13.1464C6.57399 13.2402 6.62667 13.3674 6.62667 13.5C6.62667 13.6326 6.57399 13.7598 6.48022 13.8536C6.38645 13.9473 6.25927 14 6.12667 14Z'
        fill='currentColor'
      />
      <path
        d='M8.83333 6.33317H2.5C2.36739 6.33317 2.24021 6.28049 2.14645 6.18672C2.05268 6.09296 2 5.96578 2 5.83317C2 5.70056 2.05268 5.57339 2.14645 5.47962C2.24021 5.38585 2.36739 5.33317 2.5 5.33317H8.83333C8.96594 5.33317 9.09312 5.38585 9.18689 5.47962C9.28065 5.57339 9.33333 5.70056 9.33333 5.83317C9.33333 5.96578 9.28065 6.09296 9.18689 6.18672C9.09312 6.28049 8.96594 6.33317 8.83333 6.33317ZM6.16667 8.99984H2.5C2.36739 8.99984 2.24021 8.94716 2.14645 8.85339C2.05268 8.75962 2 8.63245 2 8.49984C2 8.36723 2.05268 8.24005 2.14645 8.14628C2.24021 8.05252 2.36739 7.99984 2.5 7.99984H6.16667C6.29927 7.99984 6.42645 8.05252 6.52022 8.14628C6.61399 8.24005 6.66667 8.36723 6.66667 8.49984C6.66667 8.63245 6.61399 8.75962 6.52022 8.85339C6.42645 8.94716 6.29927 8.99984 6.16667 8.99984ZM5.5 3.6665H2.5C2.36739 3.6665 2.24021 3.61383 2.14645 3.52006C2.05268 3.42629 2 3.29911 2 3.1665C2 3.0339 2.05268 2.90672 2.14645 2.81295C2.24021 2.71918 2.36739 2.6665 2.5 2.6665H5.5C5.63261 2.6665 5.75979 2.71918 5.85355 2.81295C5.94732 2.90672 6 3.0339 6 3.1665C6 3.29911 5.94732 3.42629 5.85355 3.52006C5.75979 3.61383 5.63261 3.6665 5.5 3.6665Z'
        fill='currentColor'
      />
    </svg>
  )
}
