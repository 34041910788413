import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import FileUpload from 'components/form/FileUpload'
import Input from 'components/form/input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { BiEdit } from 'react-icons/bi'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'

type Props = {
  title?: string
  tool: Tool
}

type Form = {
  title: string
  type?: string
  file?: File
  thumbnail?: File
}

export default NiceModal.create(({ title, tool }: Props) => {
  const modal = useModal()

  const queryClient = useQueryClient()

  const updateToolMutation = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.put(`admin/marketing-tools/${tool.id}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tools')
        modal.remove()
      },
    },
  )

  const [form, setForm] = useState<Form>({
    title: tool.title,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key as keyof Form]) {
        formData.append(key, form[key as keyof Form] as any)
      }
    })

    toast.promise(updateToolMutation.mutateAsync(formData), {
      loading: 'Adding new tool...',
      success: (res) => res.message ?? 'Tool added successfully',
      error: (err) => err.message ?? 'Failed to add new tool',
    })
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    const type = file?.type.replace('application/', '').replace(/\/.*$/, '')
    setForm({ ...form, [e.target.name]: file, ['type']: type })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  return (
    <Modal
      title={title}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <FileUpload
          defaultImg={tool.thumbnail_path ? appUrl + tool.thumbnail_path : undefined}
          label='Book Thumbnail (Optional)'
          onChange={handleFileUpload}
          name='thumbnail'
        />
        <Input
          name='title'
          value={form.title}
          onChange={handleChange}
          label='Title'
          placeholder='Enter the title'
        />
        <label className='py-6 cursor-pointer relative w-full btn btn-secondary'>
          <div className='text-center'>
            {form.file?.name ? (
              <p>{form.file?.name}</p>
            ) : (
              <p>{tool.file_path && tool.file_path?.replace('storage/marketing_tools/', '')}</p>
            )}

            <BiEdit className='absolute right-1 top-1' size={20} />
          </div>
          <input
            accept='application/pdf,image/jpeg,image/png,video/mp4,video/mov,video/avi'
            name='file'
            onChange={handleFileUpload}
            type='file'
            hidden
          />
        </label>
        <Button disabled={updateToolMutation.isLoading} fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  )
})
