import clsx from 'clsx'

type Props = {
  options: TabOption[]
  selected: TabOption
  selectHandler: (selected: TabOption) => void
  size?: 'sm' | 'md' | 'lg'
}

export default function Tabs({ options, selected, selectHandler, size = 'lg' }: Props) {
  return (
    <ul
      className={clsx('sm:inline-flex flex-wrap rounded-lg overflow-hidden', {
        'h-8': size === 'sm',
        'h-10': size === 'md',
        'h-12': size === 'lg',
      })}
    >
      {options?.map((option) => (
        <li
          onClick={() => selectHandler(option)}
          className={clsx(
            'h-full grid place-items-center px-8 font-semibold text-sm cursor-pointer',
            selected.value === option.value ? 'bg-[#1C366A] text-white' : 'bg-[#D2D7E1] text-dark',
          )}
          key={option.value}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )
}
