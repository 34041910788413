import { ApexOptions } from 'apexcharts'
import Devider from 'components/Devider'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import ReactApexChart from 'react-apexcharts'

const state: { options: ApexOptions; series: ApexOptions['series'] } = {
  series: [
    {
      name: 'Sales',
      data: [0, 40, 28, 51, 42, 109, 100, 10, 20, 30, 12, 47],
    },
    {
      name: 'Commissions',
      data: [0, 32, 45, 32, 34, 52, 41, 10, 20, 30, 12, 47],
    },
    {
      name: 'Profit',
      data: [0, 21, 36, 45, 29, 45, 52, 10, 20, 30, 12, 47],
    },
  ],
  options: {
    chart: {
      height: 350,
      type: 'area',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    legend: {
      itemMargin: {
        horizontal: 0,
        vertical: 10,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: 'category',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      labels: {
        style: {
          colors: '#B3BCCD',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#B3BCCD',
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
    colors: ['#008FFB', '#00E396', '#FEB019'],
  },
}

export default function ProfitPage() {
  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8'>
        <AnalyticCard title='Sales' value='454156152.00 USDT' />
        <AnalyticCard title='Commissions' value='454156152.00 USDT' />
        <AnalyticCard title='Profit' value='454156152.00 USDT' />
      </div>
      <Devider />
      <div className='card overflow-hidden relative'>
        <ReactApexChart options={state.options} series={state.series} type='area' height={600} />
      </div>
    </div>
  )
}
