import DatePicker from 'react-date-picker'

interface Props {
  value?: Date
  onChange: (value: Date) => void
}

export default function InputDatePicker({ onChange, value }: Props) {
  return (
    <DatePicker
      className='w-auto inline-flex h-[46px] items-center px-2 rounded-lg bg-[#F3F3F3]'
      format='dd-MM-y'
      calendarIcon={null}
      clearIcon={null}
      dayPlaceholder='DD'
      monthPlaceholder='MM'
      yearPlaceholder='YY'
      onChange={onChange}
      value={value}
    />
  )
}
