import { useState } from 'react'
import { TbPhotoEdit, TbPhotoUp } from 'react-icons/tb'

type Props = {
  label: string
  name?: string
  defaultImg?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export default function FileUpload({ label, name, onChange, defaultImg }: Props) {
  const [file, setFile] = useState<File | null>(null)

  return (
    <div>
      <p className='block text-sm font-medium text-slate-700 mb-2'>{label}</p>
      <label className='block h-32 w-52 cursor-pointer overflow-hidden btn btn-secondary relative'>
        {file || defaultImg ? (
          <>
            <img
              className='h-full w-full object-cover'
              src={file ? URL.createObjectURL(file) : defaultImg}
              alt=''
            />
            <TbPhotoEdit
              size={30}
              className='absolute right-3 bottom-3 z-10 bg-primary/70 p-1 text-white rounded-md'
            />
          </>
        ) : (
          <TbPhotoUp size={35} />
        )}
        <input
          accept='image/jpeg,image/png,image/jpg'
          name={name}
          onChange={(e) => {
            onChange(e)
            console.log(e.target.files, 'e.target.files')
            setFile(e.target.files?.item(0) ?? null)
          }}
          type='file'
          hidden
        />
      </label>
    </div>
  )
}
