import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const useTags = () =>
  useQuery<Tag[], Error>('get-tags', async () => {
    try {
      const res = await privateRequest.get('admin/get-tags')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

export const useCourseCategories = () =>
  useQuery<CourseCategory[], Error>('course-categories', async () => {
    try {
      const res = await privateRequest.get('admin/courses/categories')
      return res.data.categories
    } catch (error) {
      errorHandler(error)
    }
  })

export const useEbookCategories = () =>
  useQuery<Category[], Error>('ebook-categories', async () => {
    try {
      const res = await privateRequest.get('admin/category')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })
