import clsx from 'clsx'
import { ChangeEventHandler } from 'react'
import { BsSearch } from 'react-icons/bs'

type Props = {
  label?: string
  type?: React.HTMLInputTypeAttribute
  fullWidth?: boolean
  name?: string
  disabled?: boolean
  className?: string
  inputClassName?: string
  error?: boolean
  helpText?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  value?: string | number | readonly string[] | undefined
  checked?: boolean
  placeholder?: string
  min?: number
  max?: number
  prefix?: string
  afterFix?: JSX.Element | 'search'
  readOnly?: boolean
  size?: 'sm' | 'md' | 'lg'
}

export default function Input({
  label,
  type = 'text',
  name,
  disabled = false,
  className = '',
  inputClassName = '',
  onChange,
  value = '',
  checked,
  placeholder,
  error,
  helpText,
  max,
  min,
  prefix = '',
  afterFix,
  readOnly = false,
  fullWidth = false,
  size = 'lg',
}: Props) {
  if (type === 'radio') {
    return (
      <div className={clsx('', className, { 'w-full': fullWidth })}>
        <label htmlFor={name} className='inline-flex gap-3 items-center cursor-pointer'>
          <div className='relative'>
            <input
              id={name}
              type='checkbox'
              checked={checked}
              onChange={onChange}
              className={clsx('sr-only peer bg-inherit', inputClassName)}
            />
            <div className="w-11 h-6 peer-focus:outline-none rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-slate-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-primary bg-slate-100"></div>
          </div>
          <span className='text-sm select-none font-semibold text-slate-900'>{label}</span>
        </label>
        <p className='text-red-500 text-sm font-semibold my-1'>{helpText}</p>
      </div>
    )
  }
  return (
    <div className={clsx('', className, { 'w-full': fullWidth })}>
      {label && (
        <label htmlFor={name} className='block text-sm font-medium text-slate-700 mb-2'>
          {label}
        </label>
      )}
      <div
        className={clsx(
          'relative flex items-center bg-primary-shade border border-transparent placeholder-slate-400  disabled:bg-slate-50 disabled:text-slate-500 focus:outline-none focus:ring-sky-500 w-full rounded-md overflow-hidden sm:text-sm focus:ring-1 invalid:text-pink-600  focus:invalid:ring-pink-500 disabled:shadow-none',
          { '!border-red-400': error },
          inputClassName,
          { 'h-8': size === 'sm' },
          { 'h-10': size === 'md' },
          { 'h-12': size === 'lg' },
        )}
      >
        {prefix && <span className='pl-1'>{prefix}</span>}
        <input
          readOnly={readOnly}
          min={min}
          max={max}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          type={type}
          name={name}
          id={name}
          disabled={disabled}
          className={clsx('px-3 h-full w-full outline-none bg-inherit', {
            '!pl-[1px]': prefix,
          })}
        />
        {afterFix && afterFix === 'search' ? (
          <BsSearch size={20} className='mr-3 text-gray' />
        ) : (
          afterFix
        )}
      </div>
      <p className='text-red-500 text-sm font-semibold my-1'>{helpText}</p>
    </div>
  )
}
