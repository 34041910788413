import { useModal } from '@ebay/nice-modal-react'
import InputDatePicker from 'components/DatePicker'
import Button from 'components/form/button'
import Input from 'components/form/input'
import { privateRequest } from 'config/axios.config'
import updateUserModal from 'features/member/updateUser.modal'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { dateFormatter } from 'utils'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  type: 'Free' | 'Associate'
}

export default function MembersPage({ type }: Props) {
  const [date, onChange] = useState<Date | undefined>()
  const [search, setSearch] = useState<string>('')

  const updateUser = useModal(updateUserModal)

  const { data, isLoading } = useQuery<Member[], Error>(['members', type, search], async () => {
    try {
      const res = await privateRequest.get(`admin/users?membership=${type}&username=${search}`)
      return res.data.members
    } catch (error) {
      errorHandler(error)
    }
  })

  return (
    <div className='card'>
      <div className='flex items-center flex-wrap gap-3'>
        <span className='ml-auto'>From</span> <InputDatePicker value={date} onChange={onChange} />{' '}
        To <InputDatePicker value={date} onChange={onChange} />
        <Input
          name='search'
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          afterFix='search'
          placeholder='Search username'
        />
      </div>
      <div className='max-h-[calc(100vh-270px)] overflow-auto pr-3'>
        <table className='mt-8'>
          <thead className='sticky left-0 top-0 bg-white'>
            <tr>
              <td>Joined date</td>
              <td>Username</td>
              <td>Name</td>
              <td>Email</td>
              <td align='right'>Action</td>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={4}>Loading...</td>
              </tr>
            ) : (
              data?.map((member) => (
                <tr key={member.id}>
                  <td>{dateFormatter(member.created_at)}</td>
                  <td>{member.username}</td>
                  <td>{member.name}</td>
                  <td>{member.email}</td>
                  <td align='right'>
                    <div className='inline-flex gap-2'>
                      <Button size='md'>{type === 'Free' ? 'Upgrade' : 'Downgrade'}</Button>
                      <Button
                        onClick={() => updateUser.show({ user: member })}
                        variant='outlined'
                        size='md'
                      >
                        Edit
                      </Button>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}
