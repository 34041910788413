import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  className?: string
}

export default function Alert({ children, className }: PropsWithChildren<Props>) {
  return <div className={clsx('', className)}>{children}</div>
}
