import Devider from 'components/Devider'
import Button from 'components/form/button'
import Input from 'components/form/input'
import AddIcon from 'components/icons/AddIcon'
import NoData from 'components/NoData'
import Tabs from 'components/Tabs'
import Title from 'components/Title'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import { useState } from 'react'

const tabs: TabOption[] = [
  {
    label: 'Locked',
    value: 'Locked',
  },
  {
    label: 'Flexible',
    value: 'Flexible',
  },
]

type Filter = {
  search: string
}

export default function StakingPage() {
  const [filters, setFilters] = useState<Filter>({
    search: '',
  })

  const [selectedTab, setSelectedTab] = useState(tabs[0])

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }
  return (
    <>
      <div className='grid sm:grid-cols-2 xl:grid-cols-3 gap-8'>
        <AnalyticCard title='Locked Staking' value='89544448.00 USDT' />
        <AnalyticCard title='Flexible Staking' value='89544448.00 USDT' />
        <AnalyticCard title='Total Interest Paid' value='89544448.00 USDT' />
      </div>
      <Devider />
      <div className='card'>
        <div className='flex items-center flex-wrap justify-between mb-6'>
          <Tabs options={tabs} selected={selectedTab} selectHandler={setSelectedTab} />
          <div className='flex gap-5 flex-wrap justify-end items-center'>
            <Button className='!rounded-full'>
              <AddIcon size={14} /> Add New
            </Button>
            <Input
              inputClassName='!rounded-full'
              name='search'
              value={filters.search}
              onChange={changeHandler}
              afterFix='search'
              placeholder='Search assets'
            />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <td>Coin</td>
              <td>Category</td>
              <td>Duration</td>
              <td>APY</td>
              <td>Min Liquidity</td>
              <td>Total Liquidity</td>
              <td>Remaining Liquidity</td>
              <td align='right'>Action</td>
            </tr>
          </thead>
          <tbody>
            {/* {transactions?.map((row) => (
              <tr key={row.id}>
                <td>{row.reference}</td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <Button>Approve</Button>
                  <Button>Decline</Button>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
        <NoData />
      </div>

      <Devider />

      <div className='card'>
        <Title className='flex items-center mb-5'>
          Subscription List{' '}
          <Button to='/fincare/subscriptions' size='md' className='ml-auto'>
            See All
          </Button>
        </Title>

        <table>
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>TxID</td>
              <td>Coin</td>
              <td>Username</td>
              <td>Duration</td>
              <td>Staking Type</td>
              <td>Staking Amount</td>
              <td>APY</td>
            </tr>
          </thead>
        </table>
      </div>
    </>
  )
}
