import { BsYoutube } from 'react-icons/bs'

import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Button from 'components/form/button'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'
import editCourse from './editCourse'

type Props = {
  course: Course
}

export default function CourseCard({ course }: Props) {
  const confirmation = useModal(confirmationModal)
  const editCourseModal = useModal(editCourse)
  const queryClient = useQueryClient()
  const deleteCourse = useMutation<{ message: string }, Error, string>(
    async (id) => {
      try {
        const res = await privateRequest.delete(`admin/course/${id}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('courses')
      },
    },
  )

  return (
    <div className='border border-primary-shade p-3'>
      <div className='relative mb-3'>
        <img
          className='h-64 w-full rounded-md overflow-hidden object-cover'
          src={appUrl + course.video_thumbnail}
          alt=''
        />
        <BsYoutube
          className='text-white absolute left-1/2
         top-1/2 -translate-x-1/2 -translate-y-1/2'
          size={50}
        />
      </div>
      <h6 className='text-lg font-medium text-dark mb-2 whitespace-nowrap text-ellipsis overflow-hidden'>
        {course.title}
      </h6>
      <h3 className='text-sm font-medium text-dark mb-3 max-h-12 overflow-hidden'>
        {course.description?.substring(0, 70)} {course.description?.length > 70 ? '...' : null}
      </h3>
      <div className='flex justify-between items-center'>
        Tag
        <span
          className={clsx(
            `btn btn-default p-1 text-sm badge-${StatusColorFinder(course.tag?.name)}`,
          )}
        >
          {course.tag?.name}
        </span>
      </div>
      <div className='grid grid-cols-2 gap-2 mt-4'>
        <Button onClick={() => editCourseModal.show({ title: 'Edit Course', course })} size='md'>
          Edit
        </Button>
        <Button
          disabled={deleteCourse.isLoading}
          onClick={() =>
            confirmation
              .show({
                title: 'Are you sure you want to delete this course?',
                buttonText: 'Delete',
                phase: 'danger',
              })
              .then(() => {
                toast.promise(deleteCourse.mutateAsync(course.id), {
                  loading: 'Deleting course...',
                  success: (res) => res.message ?? 'Course deleted successfully',
                  error: (err) => err.message ?? 'Something went wrong',
                })
              })
          }
          size='md'
          variant='outlined'
        >
          Delete
        </Button>
      </div>
    </div>
  )
}
