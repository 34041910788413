import { useModal } from '@ebay/nice-modal-react'
import clsx from 'clsx'
import Button from 'components/form/button'
import confirmationModal from 'components/modal/confirmation.modal'
import { privateRequest } from 'config/axios.config'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { StatusColorFinder } from 'utils/StatusColorFinder'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'
import editBook from './editBook'

type Props = {
  book: Book
}

export default function EbookCard({ book }: Props) {
  const confirmation = useModal(confirmationModal)
  const queryClient = useQueryClient()

  const deleteBook = useMutation<{ message: string }, Error, number>(
    async (id) => {
      try {
        const res = await privateRequest.delete(`admin/ebook/${id}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ebooks')
      },
    },
  )

  const editBookModal = useModal(editBook)

  return (
    <div className='border border-primary-shade p-3 grid grid-cols-5 gap-6'>
      <div className='col-span-2'>
        {book.book_cover_image && (
          <div className='shadow-xl'>
            <img
              className='h-40 w-full object-cover rounded-md overflow-hidden'
              src={appUrl + book.book_cover_image}
              alt=''
            />
          </div>
        )}
      </div>
      <div className='col-span-3'>
        <h3 className='text-base font-medium text-dark'>{book.book_title}</h3>
        <h6 className='text-xs font-medium text-gray mb-2'>{book.book_author}</h6>
        <div className='flex justify-between items-center'>
          Tag
          <span
            className={clsx(
              `btn btn-default p-1 text-sm badge-${StatusColorFinder(book.tag?.name)}`,
            )}
          >
            {book.tag?.name}
          </span>
        </div>
        <div className='mt-8 grid gap-3 grid-cols-2'>
          <Button onClick={() => editBookModal.show({ book, title: 'Edit Ebook' })} size='md'>
            Edit
          </Button>
          <Button
            disabled={deleteBook.isLoading}
            onClick={() =>
              confirmation
                .show({
                  title: 'Are you sure you want to delete this ebook?',
                  buttonText: 'Delete',
                  phase: 'danger',
                })
                .then(() => {
                  toast.promise(deleteBook.mutateAsync(book.id), {
                    loading: 'Deleting ebook...',
                    success: (res) => res.message ?? 'Ebook deleted successfully',
                    error: (err) => err.message ?? 'Something went wrong',
                  })
                })
            }
            variant='outlined'
            size='md'
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  )
}
