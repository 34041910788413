import { privateRequest } from 'config/axios.config'
import { useQuery } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

export const getBalances = () =>
  useQuery<BalancesResponse, Error>('get-balances', async () => {
    try {
      const res = await privateRequest.get('user/balances')
      return res.data
    } catch (err) {
      errorHandler(err)
    }
  })

export const getTransactions = (
  source: TransactionSourceType,
  walletName?: WalletType,
  search?: string,
) =>
  useQuery<Transaction[], Error>(['get-transactions', source, walletName, search], async () => {
    try {
      const res = await privateRequest.get(
        `user/transactions?source=${source}&wallet_name=${walletName}&search=${search}`,
      )
      return res.data.data
    } catch (err) {
      errorHandler(err)
    }
  })
