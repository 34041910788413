import { useState } from 'react'

import BreadCrumb from 'components/BreadCrumb'
import Input from 'components/form/input'
import Select from 'components/form/select'
import NoData from 'components/NoData'

type SortByOptions = { label: string; value: TransactionSourceType }

interface Filter {
  search: string
  sortby: SortByOptions
}

export default function SubscriptionsPage() {
  const [filters, setFilters] = useState<Filter>({
    search: '',
    sortby: {
      label: 'All',
      value: 'All',
    },
  })

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className='card'>
      <div className='flex items-center gap-8 mb-6'>
        <BreadCrumb title='Staking' prevPage='/fincare/staking' />
        <div className='ml-auto flex gap-5 flex-wrap justify-end items-center'>
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix='search'
            placeholder='Search TxID'
          />
          <Select
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={[
              {
                label: 'All',
                value: 'All',
              },
            ]}
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>Timestamp</td>
            <td>TXID</td>
            <td>Coin</td>
            <td>Username</td>
            <td>Duration</td>
            <td>Staking Type</td>
            <td>Staking Amount</td>
            <td>APY</td>
          </tr>
        </thead>
        <tbody>
          {/* {transactions?.map((row) => (
              <tr key={row.id}>
                <td>{row.reference}</td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <div className='flex gap-2 justify-end'>
                    <Button size='md'>Approve</Button>
                    <Button size='md' color='danger' variant='outlined'>
                      Decline
                    </Button>
                  </div>
                </td>
              </tr>
            ))} */}
        </tbody>
      </table>
      <NoData />
    </div>
  )
}
