import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import Input from 'components/form/input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  user: Member
}

type Form = {
  name: string
}

export default NiceModal.create(({ user }: Props) => {
  const modal = useModal()

  const queryClient = useQueryClient()

  const updateUser = useMutation<{ message: string }, Error>(
    async () => {
      try {
        const res = await privateRequest.put(`admin/user/${user.id}?name=${form.name}`)
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('members')
        modal.remove()
      },
    },
  )

  const [form, setForm] = useState<Form>({
    name: user.name,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key as keyof Form]) {
        formData.append(key, form[key as keyof Form] as any)
      }
    })

    toast.promise(updateUser.mutateAsync(), {
      loading: 'Updating...',
      success: (res) => res.message ?? 'Updated successfully',
      error: (err) => err.message ?? 'Failed to update user',
    })
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  return (
    <Modal
      title='Update User'
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <Input
          name='name'
          value={form.name}
          onChange={handleChange}
          label='Name'
          placeholder='Enter the name'
        />
        <Button disabled={updateUser.isLoading} fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  )
})
