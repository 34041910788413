import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import FileUpload from 'components/form/FileUpload'
import Input from 'components/form/input'
import Select from 'components/form/select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'
import { appUrl } from 'utils/url'

type Props = {
  title?: string
  course: Course
}

type Form = {
  title: string
  description: string
  video_url: string
  flag: 'paid' | 'free'
  video_thumbnail?: File
  category_id: string
  tag_id: string
}

export default NiceModal.create(({ title, course }: Props) => {
  const modal = useModal()

  const queryClient = useQueryClient()

  const { data: tags } = useQuery<Tag[], Error>('admin/courses/course-tags', async () => {
    try {
      const res = await privateRequest.get('admin/courses/course-tags')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const { data: categories } = useQuery<CourseCategory[], Error>('course-categories', async () => {
    try {
      const res = await privateRequest.get('admin/courses/categories')
      return res.data.categories
    } catch (error) {
      errorHandler(error)
    }
  })

  const addNewEbookMutation = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post(`admin/course/${course.id}`, payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('courses')
        modal.remove()
      },
    },
  )

  const [form, setForm] = useState<Form>({
    title: course.title,
    description: course.description,
    video_url: course.video_url,
    flag: course.flag,
    video_thumbnail: undefined,
    category_id: course.category_id,
    tag_id: course.tag_id,
  })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key as keyof Form]) {
        formData.append(key, form[key as keyof Form] as any)
      }
    })

    toast.promise(addNewEbookMutation.mutateAsync(formData), {
      loading: 'Updating new course...',
      success: (res) => res.message ?? 'Course updated successfully',
      error: (err) => err.message ?? 'Failed to updating course',
    })
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setForm({ ...form, [e.target.name]: file })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
  }

  const handleSelectChange = (e: _SelectChangeHandlerEvent) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value.value })
  }

  const categoriedOptions = categories?.map((c) => ({
    label: c.name,
    value: String(c.id),
  }))

  const tagOptions = tags?.map((el) => ({ label: el.name as string, value: String(el.id) }))

  return (
    <Modal
      title={title}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <FileUpload
          label='Course Video Thumbnail (Optional)'
          onChange={handleFileUpload}
          name='video_thumbnail'
          defaultImg={appUrl + course.video_thumbnail}
        />
        <Input
          name='title'
          value={form.title}
          onChange={handleChange}
          label='Course Title'
          placeholder='Enter the title'
        />
        <Input
          name='description'
          value={form.description}
          onChange={handleChange}
          label='Course Description'
          placeholder='Enter the description'
        />

        <Select
          placeholder='Select Category'
          label='Category'
          options={categoriedOptions ?? []}
          name='category_id'
          value={categoriedOptions?.find((c) => c.value === form.category_id)}
          onChange={handleSelectChange}
        />

        <Select
          placeholder='Select Tag'
          label='Select Tag'
          options={tagOptions ?? []}
          name='tag_id'
          value={tagOptions?.find((c) => c.value === form.tag_id)}
          onChange={handleSelectChange}
        />

        <Input
          name='video_url'
          value={form.video_url}
          onChange={handleChange}
          label='Course Video URL'
          placeholder='Enter the video url'
        />

        <Button disabled={addNewEbookMutation.isLoading} fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  )
})
