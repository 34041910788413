import { AuthContext } from 'context/AuthContext'
import { PropsWithChildren, useContext } from 'react'
import { CiImageOn } from 'react-icons/ci'

type Props = {
  title: string
}

export default function PageWrapper({ title, children }: PropsWithChildren<Props>) {
  const { setDrawerShow, user } = useContext(AuthContext)

  return (
    <div>
      <header className='bg-white flex items-center px-9 py-9'>
        <svg
          className='lg:hidden cursor-pointer mr-3'
          onClick={() => setDrawerShow(true)}
          stroke='currentColor'
          fill='currentColor'
          strokeWidth='0'
          viewBox='0 0 1024 1024'
          height='25'
          width='25'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M904 160H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0 624H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8zm0-312H120c-4.4 0-8 3.6-8 8v64c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-64c0-4.4-3.6-8-8-8z'></path>
        </svg>
        <h2 className='text-2xl font-medium'>{title}</h2>
        <div className='ml-auto flex items-center gap-4'>
          {user?.name && <div className='text-dark text-sm font-medium'>{user.name}</div>}
          <div className='h-10 w-10 text-dark border-2 border-primary/60 rounded-full grid place-items-center'>
            <CiImageOn />
          </div>
        </div>
      </header>
      <main className='p-4 md:p-9'>{children}</main>
    </div>
  )
}
