import { useState } from 'react'

import InputDatePicker from 'components/DatePicker'
import Devider from 'components/Devider'
import Input from 'components/form/input'
import Select from 'components/form/select'
import NoData from 'components/NoData'
import AnalyticCard from 'features/dashboard/AnalyticCard'

const sortbyOptions: SortByOptions[] = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Dual Team',
    value: 'dual_team',
  },
  {
    label: 'Signup',
    value: 'signup',
  },
  {
    label: 'Direct Referral',
    value: 'direct_referral',
  },
]

type SortByOptions = { label: string; value: TransactionSourceType }

interface Filter {
  search: string
  sortby: SortByOptions
}

export default function ExchangePage() {
  const [date, onChange] = useState<Date | undefined>()
  const [filters, setFilters] = useState<Filter>({
    search: '',
    sortby: sortbyOptions[0],
  })

  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }
  return (
    <>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-8'>
        <AnalyticCard title='Total Exchange Amount' value={'89548.00 USDT'} />
        <AnalyticCard title='Total Exchange Fee' value={'89548.00 USDT'} />
      </div>
      <Devider />
      <div className='card'>
        <div className='flex gap-5 flex-wrap mb-6 justify-end items-center'>
          From <InputDatePicker value={date} onChange={onChange} /> To{' '}
          <InputDatePicker value={date} onChange={onChange} />
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix='search'
            placeholder='Search TxID'
          />
          <Select
            className='!min-w-[200px]'
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={sortbyOptions}
          />
        </div>
        <table>
          <thead>
            <tr>
              <td>Timestamp</td>
              <td>TXID</td>
              <td>Username</td>
              <td>Amount</td>
              <td align='right'>Status</td>
            </tr>
          </thead>
          <tbody>
            {/* {transactions?.map((row) => (
              <tr key={row.id}>
                <td>{row.reference}</td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <div className='flex gap-2 justify-end'>
                    <Button size='md'>Approve</Button>
                    <Button size='md' color='danger' variant='outlined'>
                      Decline
                    </Button>
                  </div>
                </td>
              </tr>
            ))} */}
          </tbody>
        </table>
        <NoData />
      </div>
    </>
  )
}
