import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import FileUpload from 'components/form/FileUpload'
import Input from 'components/form/input'
import Select from 'components/form/select'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import { useTags } from 'queries/academy'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { RxUpload } from 'react-icons/rx'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  title?: string
  book_category_id: string
}

type Form = {
  book_title: string
  book_author: string
  book_short_description: string
  book_file_url?: File
  tag_id: string
  book_category_id: string
  book_cover_image?: File
}

export default NiceModal.create(({ title, book_category_id }: Props) => {
  const modal = useModal()

  const queryClient = useQueryClient()

  const addNewEbookMutation = useMutation<{ message: string }, Error, FormData>(
    async (payload) => {
      try {
        const res = await privateRequest.post('admin/ebook/store', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('ebooks')
        modal.remove()
      },
    },
  )

  const [form, setForm] = useState<Form>({
    book_title: '',
    book_author: '',
    book_short_description: '',
    book_category_id: book_category_id,
    book_cover_image: undefined,
    book_file_url: undefined,
    tag_id: '',
  })

  const [errors, setErrors] = useState<{
    book_title?: string
    book_author?: string
    book_short_description?: string
    book_file_url?: string
    book_cover_image?: string
    tag_id?: string
    book_category_id?: string
  }>({})

  const { data: categories } = useQuery<Category[], Error>('ebook-categories', async () => {
    try {
      const res = await privateRequest.get('admin/category')
      return res.data.data
    } catch (error) {
      errorHandler(error)
    }
  })

  const { data: tags } = useTags()

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const formData = new FormData()
    Object.keys(form).forEach((key) => {
      if (form[key as keyof Form]) {
        formData.append(key, form[key as keyof Form] as any)
      }
    })

    if (
      !form.book_title ||
      !form.book_author ||
      !form.book_short_description ||
      !form.book_file_url ||
      !form.book_cover_image ||
      !form.tag_id ||
      !form.book_category_id
    ) {
      return setErrors({
        book_title: !form.book_title ? 'Book title is required' : undefined,
        book_author: !form.book_author ? 'Book author is required' : undefined,
        book_short_description: !form.book_short_description
          ? 'Book description is required'
          : undefined,
        book_file_url: !form.book_file_url ? 'Book file is required' : undefined,
        book_cover_image: !form.book_cover_image ? 'Book thumbnail is required' : undefined,
        tag_id: !form.tag_id ? 'Tag is required' : undefined,
        book_category_id: !form.book_category_id ? 'Category is required' : undefined,
      })
    }

    toast.promise(addNewEbookMutation.mutateAsync(formData), {
      loading: 'Adding new ebook...',
      success: (res) => res.message ?? 'Ebook added successfully',
      error: (err) => err.message ?? 'Failed to add new ebook',
    })
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return
    setForm({ ...form, [e.target.name]: file })
    setErrors({ ...errors, [e.target.name]: undefined })
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value })
    setErrors({ ...errors, [name]: undefined })
  }

  const handleSelectChange = (e: _SelectChangeHandlerEvent) => {
    const { name, value } = e.target
    setForm({ ...form, [name]: value.value })
  }

  const categoriedOptions = categories?.map((c) => ({
    label: c.category_name,
    value: String(c.id),
  }))

  const tagOptions = tags?.map((el) => ({ label: el.name as string, value: String(el.id) }))

  return (
    <Modal
      title={title}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-2xl'
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-6'>
        <div>
          <FileUpload
            label='Book Thumbnail (Optional)'
            onChange={handleFileUpload}
            name='book_cover_image'
          />
          {errors.book_cover_image && (
            <p className='text-red-500 text-sm font-semibold my-1'>{errors.book_cover_image}</p>
          )}
        </div>
        <Input
          name='book_title'
          value={form.book_title}
          onChange={handleChange}
          label='Book Title'
          placeholder='Enter the title'
          helpText={errors.book_title}
          error={!!errors.book_title}
        />
        <Input
          name='book_short_description'
          value={form.book_short_description}
          onChange={handleChange}
          label='Book Description'
          placeholder='Enter the description'
          helpText={errors.book_short_description}
          error={!!errors.book_short_description}
        />
        <Input
          name='book_author'
          value={form.book_author}
          onChange={handleChange}
          label='Author Name'
          placeholder='@rme_username'
          helpText={errors.book_author}
          error={!!errors.book_author}
        />

        <Select
          placeholder='Select Category'
          label='Category'
          options={categoriedOptions ?? []}
          name='book_category_id'
          value={categoriedOptions?.find((c) => c.value === form.book_category_id)}
          onChange={handleSelectChange}
          helpText={errors.book_category_id}
          error={!!errors.book_category_id}
        />

        <Select
          placeholder='Select Tag'
          label='Tag'
          options={tagOptions ?? []}
          name='tag_id'
          value={tagOptions?.find((c) => c.value === form.tag_id)}
          onChange={handleSelectChange}
          helpText={errors.tag_id}
          error={!!errors.tag_id}
        />

        <div>
          <label className='py-6 cursor-pointer w-full btn btn-secondary'>
            {form.book_file_url?.name ? (
              form.book_file_url?.name
            ) : (
              <>
                <RxUpload size={20} /> Upload Attachment
              </>
            )}
            <input
              accept='application/pdf'
              name='book_file_url'
              onChange={handleFileUpload}
              type='file'
              hidden
            />
          </label>
          {errors.book_file_url && (
            <p className='text-red-500 text-sm font-semibold my-1'>{errors.book_file_url}</p>
          )}
        </div>
        <Button disabled={addNewEbookMutation.isLoading} fullWidth>
          Submit
        </Button>
      </form>
    </Modal>
  )
})
