/**
 * @param {string} type - The `type` parameter is a string that represents the status
 * @returns `StatusColor` are 'success', 'danger', 'warning', and 'info'.
 */
export const StatusColorFinder = (type: string): StatusColor => {
  if (['credit', 'confirmed', 'FREE'].includes(type)) {
    return 'success'
  } else if (['debit', 'PREMIUM'].includes(type)) {
    return 'danger'
  } else if (['pending', 'EXCLUSIVE'].includes(type)) {
    return 'warning'
  }

  return 'info'
}
