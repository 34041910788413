import NiceModal, { useModal } from '@ebay/nice-modal-react'

import Button from 'components/form/button'
import Input from 'components/form/input'
import Modal from 'components/modal'
import { privateRequest } from 'config/axios.config'
import folderImg from 'images/folder.png'
import { SyntheticEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation, useQueryClient } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Props = {
  title?: string
  data: CourseCategory
}

export default NiceModal.create(({ title, data }: Props) => {
  const modal = useModal()

  const [category, setCategory] = useState<string>(data?.name)
  const queryClient = useQueryClient()

  const addCategory = useMutation<{ message: string }, Error, string>(
    async (category_name) => {
      try {
        const res = await privateRequest.post(
          `admin/courses/category/${data.id}?name=${category_name}`,
        )
        return res.data
      } catch (error) {
        errorHandler(error)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('course-categories')
        modal.remove()
      },
    },
  )

  const submitHandler = (e: SyntheticEvent) => {
    e.preventDefault()
    toast.promise(addCategory.mutateAsync(category), {
      loading: 'Updating Category',
      success: (data) => data.message ?? 'Updated Created',
      error: (err) => err.message ?? 'Error Updating Category',
    })
  }

  return (
    <Modal
      title={title}
      visible={modal.visible}
      onCancel={() => modal.remove()}
      className='max-w-xl'
    >
      <form onSubmit={submitHandler}>
        <div className='flex gap-6 mb-6'>
          <img width={150} src={folderImg} alt='' />
          <Input
            error={addCategory.isError && !category}
            inputClassName='mt-5'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
            label='Category Name'
            placeholder='Enter Category Name'
          />
        </div>
        <Button disabled={addCategory.isLoading} fullWidth>
          Update
        </Button>
      </form>
    </Modal>
  )
})
