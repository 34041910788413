import Button from 'components/form/button'
import Input from 'components/form/input'
import InputAfterFixShowHider from 'components/form/InputAfterFixShowHider'
import { privateRequest } from 'config/axios.config'
import { AuthContext } from 'context/AuthContext'
import AuthLayout from 'layout/AuthLayout'
import { SyntheticEvent, useContext, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { errorHandler } from 'utils/errorHandler'

type Form = {
  email: string
  password: string
}

export default function LoginPage() {
  const { setToken } = useContext(AuthContext)
  const [form, setForm] = useState<Form>({
    email: '',
    password: '',
  })

  const [errors, setErrors] = useState<Partial<Form>>({
    email: '',
    password: '',
  })

  const loginMutation = useMutation<{ access_token: string; message: string }, Error, Form>(
    async (payload) => {
      try {
        const res = await privateRequest.post(
          `admin-login?email=${payload.email}&password=${payload.password}`,
        )
        return res.data
      } catch (err: any) {
        setErrors({
          email: err?.response?.data?.errors?.email ?? undefined,
          password: err?.response?.data?.errors?.password ?? undefined,
        })

        errorHandler(err)
      }
    },
    {
      onSuccess: (data) => {
        if (!data.access_token) return
        localStorage.setItem('token', data.access_token)
        setToken(data.access_token)
      },
    },
  )

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)

  const changeHandler = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const onSubmit = (e: SyntheticEvent) => {
    e.preventDefault()
    if (!form.email || !form.password) return toast.error('Please fill all the fields')

    toast.promise(loginMutation.mutateAsync(form), {
      loading: 'Logging in...',
      success: (res) => res.message || 'Logged in successfully',
      error: (err) => err?.message || 'Failed to login',
    })
  }

  return (
    <AuthLayout title='Admin Login' description='Please provide correct email & password'>
      <form className='flex flex-col gap-6' onSubmit={onSubmit}>
        <Input
          onChange={changeHandler}
          value={form.email}
          label='Email'
          name='email'
          placeholder='Enter your email'
          helpText={errors.email}
        />
        <Input
          onChange={changeHandler}
          value={form.password}
          label='Password'
          name='password'
          placeholder='Enter your password'
          type={isPasswordVisible ? 'text' : 'password'}
          afterFix={
            <InputAfterFixShowHider
              isVisible={isPasswordVisible}
              onClick={() => setIsPasswordVisible((prev) => !prev)}
              type='eye'
            />
          }
          helpText={errors.password}
        />
        <div className='flex justify-end'>
          <Button className='!px-10'>Log In</Button>
        </div>
      </form>
    </AuthLayout>
  )
}
