import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type Props = {
  title: string
  value: number | string
  className?: string
}

export default function AnalyticCard({
  title,
  value,
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <div className={clsx('bg-white p-6 rounded-md', className)}>
      <h5 className='text-base text-gray mb-2'>{title}</h5>
      {/* <p className='text-base text-green-500 flex items-center'>
          <BsFillCaretDownFill />
          {value}%
        </p> */}
      <h2 className='text-2xl font-semibold'>{value}</h2>
      {children}
    </div>
  )
}
