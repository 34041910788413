import Devider from 'components/Devider'
import Input from 'components/form/input'
import Select from 'components/form/select'
import { Loader } from 'components/Loader'
import NoData from 'components/NoData'
import Status from 'components/Status'
import AnalyticCard from 'features/dashboard/AnalyticCard'
import { useState } from 'react'
import { dateFormatter } from 'utils'
import { StatusColorFinder } from 'utils/StatusColorFinder'

type SortByOptions = { label: string; value: TransactionSourceType }

interface WalletFilter {
  search: string
  sortby: SortByOptions
}

const sortbyOptions: SortByOptions[] = [
  {
    label: 'All',
    value: 'All',
  },
]

export default function GamingRewardPage() {
  const changeHandler = ({ target: { name, value } }: any) => {
    setFilters((prev) => ({ ...prev, [name]: value }))
  }
  const [filters, setFilters] = useState<WalletFilter>({
    search: '',
    sortby: sortbyOptions[0],
  })
  const data: any = [],
    isLoading = false

  return (
    <>
      <AnalyticCard className='max-w-xs' title='Total Rewards Sent' value='89544448.00 USDT' />
      <Devider />
      <div className='card'>
        <div className='flex gap-5 flex-wrap mb-6 justify-end items-center'>
          <Input
            name='search'
            value={filters.search}
            onChange={changeHandler}
            afterFix='search'
            placeholder='Search TxID'
          />
          <Select
            className='!min-w-[200px]'
            name='sortby'
            value={filters.sortby}
            onChange={changeHandler}
            align='right'
            options={sortbyOptions}
          />
        </div>
        <table>
          <thead>
            <tr>
              <td>TXID</td>
              <td>Timestamp</td>
              <td>Amount</td>
              <td>Note</td>
              <td align='right'>Status</td>
            </tr>
          </thead>
          <tbody>
            {data?.map((row: any) => (
              <tr key={row.id}>
                <td>{row.reference}</td>
                <td>{dateFormatter(row.created_at)}</td>
                <td>
                  {row.type === 'credit' ? (
                    <span className='text-green-500'>+</span>
                  ) : (
                    <span className='text-red-500'>-</span>
                  )}{' '}
                  {row.amount}
                </td>
                <td>{row.note}</td>
                <td align='right'>
                  <Status color={StatusColorFinder(row.status)}>{row.status}</Status>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isLoading ? <Loader className='py-28' /> : !data?.length ? <NoData /> : null}
      </div>
    </>
  )
}
