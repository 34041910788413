import FincareIcon from 'components/icons/FincareIcon'
import RewardIcon from 'components/icons/RewardIcon'
import { BiMoneyWithdraw } from 'react-icons/bi'
import { HiOutlineAcademicCap } from 'react-icons/hi2'
import { IoIosGitNetwork } from 'react-icons/io'
import { IoGameControllerOutline, IoWalletOutline } from 'react-icons/io5'
import { RxDashboard } from 'react-icons/rx'
import { VscTools } from 'react-icons/vsc'

export const drawerLinks: DrawerLink[] = [
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: RxDashboard,
  },
  {
    label: 'Wallet',
    link: '/wallet',
    icon: IoWalletOutline,
  },
  {
    label: 'All Members',
    link: '/members',
    icon: IoIosGitNetwork,
    child: [
      {
        label: 'Free Members',
        link: 'members/free',
      },
      {
        label: 'Associate Members',
        link: 'members/associate',
      },
    ],
  },
  {
    label: 'Academy',
    link: '/academy',
    icon: HiOutlineAcademicCap,
    child: [
      {
        label: 'e-Books',
        link: '/academy/ebook/categories',
      },
      {
        label: 'Courses',
        link: '/academy/course/categories',
      },
    ],
  },
  {
    label: 'Fincare',
    link: '/fincare',
    icon: FincareIcon,
    child: [
      {
        label: 'Exchange',
        link: '/fincare/exchange',
      },
      {
        label: 'Staking',
        link: '/fincare/staking',
      },
    ],
  },
  {
    label: 'Withdrawals',
    link: '/withdrawals',
    icon: BiMoneyWithdraw,
    child: [
      {
        label: 'Pending Withdrawals',
        link: '/withdrawals/pending',
      },
      {
        label: 'Withdrawals History',
        link: '/withdrawals/history',
      },
    ],
  },
  {
    label: 'Gaming Rewards',
    link: '/gaming-rewards',
    icon: IoGameControllerOutline,
  },
  {
    label: 'Profit & Loss',
    link: '/profit-loss',
    icon: RewardIcon,
  },
  {
    label: 'Marketing Tools',
    link: '/marketing-tools',
    icon: VscTools,
  },
]
